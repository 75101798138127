@import url('https://fonts.googleapis.com/css?family=Noto+Serif+Display:thin,regular,bold,italic&subset=latin,latin-ext');

@mixin clearfix {
  content: '';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}

$corp-font:'Noto Serif Display', serif;
$system-font: 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',  sans-serif;

$primary-color:#25C3B1;
$secondary-color:#2580c3;
$tertery-color:#7C7C7C;
$quaternary-color:#EFEAEA;
$quintary-color:#F92371;

$alert-nominal:#30DBC2;
$alert-error:#8F2C03;

//sizes
$micro-size: 0.563rem;
$fly-size: 1rem;
$small-size: 1.77rem;
$med-size:  2.369rem;
$large-size: 3.157rem;
$title-size: 5.61rem;

.destroy {
  background-color:red;
  color:red;
}

body {
  background-color:$primary-color;
  font-family:$system-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden;
}

body.get-route {
  background-color:$secondary-color;
}

html,
body,
.App {
  min-height:100vh;
  margin:0 !important;
}

a {
  color:inherit;
  text-decoration:none;
}

h1,
h2,
h3,
h4 {
  font-family: $system-font;
  font-weight:900;
}

h1 {
  font-family: $corp-font;
  margin-bottom:$med-size;
  color:white;
  text-align:center;
  font-size:$title-size;
}

h2 {
  font-size:$small-size;
  margin-bottom:$med-size;
  .descriptor {
    display:block;
    color:$tertery-color;
    font-size:$med-size;
  }
}

.fly {
  display:block;
  font-size:$fly-size !important;
  font-family: $system-font !important;
  margin:$micro-size 0;
  a {
    text-decoration:underline;
  }
}

form {
  max-width:960px;
  margin:auto auto;
  label {
    position:relative;
    display:block;
    text-align:left;
    margin: $small-size 0;
    font-family:$system-font;
    text-transform:uppercase;
    p{
      position:absolute;
      top:0;
      left:$micro-size;
      font-size:$fly-size !important;
    }
    input:not(input[type=checkbox]),
    textarea{
      appearance:none;
      border:none;
      display:block;
      font-family:$system-font;
      font-size:$small-size;
      padding: $fly-size;
      background:rgba($primary-color, 0.1);
      width:100%;
      border-radius:$micro-size;
      &+p {
        font-size:$med-size;
      }
      &:not(:placeholder-shown) + p,
      &:focus + p {
        top:-($small-size*0.9);
        font-size:$micro-size !important;
        animation-name:form-label;
        animation-duration:0.25s;
        outline:none !important;
      }
    }
    input[type='checkbox'] {
      top:-($micro-size*4);
      appearance:none;
      background-color:#eee;
      padding:$micro-size*0.5;
      margin-right:$fly-size;
      border-radius:50%;
      border:solid thick #eee;
      &:checked {
        background-color:$primary-color;
      }
    }
    textarea{
      height:$title-size*3;
    }
    select{
      appearance:none;
      border:none;
      display:block;
      font-family:$system-font;
      font-size:$small-size;
      padding: $fly-size;
      background:rgba($primary-color, 0.1);
      width:100%;
      border-radius:$micro-size;
      & + p {
        display:none;
      }
      &:valid + p {
        display:block;
        top:-($small-size);
        font-size:$micro-size !important;
        animation-name:form-label;
        animation-duration:0.25s;
      }
    }
  }
  .input-name {
    width:47.5%;
    display:inline-block;
    margin-bottom:0;
    & + .input-name {
      margin-left:5%;
    }
  }
  .file {
    background-color:transparent;
    padding:none;
    p {
      position:relative;
    }
    .custom-file-input::-webkit-file-upload-button {
      appearance:none;
      border:none;
      display:block;
      font-family:$system-font;
      font-size:$small-size;
      padding: $fly-size;
      margin-right:$small-size;
      background:#aaa;
      width:auto;
      color:white;
      border-radius:$micro-size;
    }
    input[type=file] {
      background-color:transparent;
      padding:0;
      margin-bottom:$fly-size;
    }
  }
  input[type=submit] {
    appearance:none;
    border:none;
    display:block;
    font-family:$system-font;
    font-size:$small-size;
    padding:$fly-size;
    padding-bottom:$micro-size;
    background:$primary-color;
    color:white;
    width:100%;
    border-radius:$micro-size;
    cursor:pointer;
    &:disabled {
      background-color:#eee !important;
    }
  }

}

.overlay {
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:100vh;
  background-color:rgba(0,0,0,0.6);
  z-index:100000000;
  .overlay-card {
    position:absolute;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    width:80%;
    max-width: 600px;
    height:auto;
    max-height:90vh;
    background-color:white !important;
    border-radius:$small-size;
    overflow-y:scroll;
    &::-webkit-scrollbar {
      background-color: transparent;
      scrollbar-width: thin;
    }
    &::-webkit-scrollbar-track{
      margin:$small-size $micro-size;
    }
    &::-webkit-scrollbar-thumb {
      background-color:$primary-color;
      border:solid white 4px;
      border-radius:$micro-size;
    }
    .close-btn {
      position:absolute;
      color:white;
      aspect-ratio: 1 / 1;
      top:$fly-size;
      right:$fly-size;
      cursor:pointer;
      z-index:10000;
    }
    .charity-details {
      width:80%;
      margin: auto;
      text-align:center;
      margin-top:$large-size;
      margin-bottom:$title-size*1.5;
      h2 {
        font-size: $med-size;
        font-weight:400;
        color:$tertery-color;
        margin-bottom:$med-size;
        & + h3 {
          margin-bottom:$small-size;
        }
      }
      .charity-icon {
        display:none;
      }
    }
  }
}

.overlay-close {
  display:none;
}

.overlay-open {
  display:block;
}


section {
  width:100vw;
  min-height:100vh;
  height:100%;
}

.watch-list {
  position:fixed;
  width:100%;
  background-color:$quintary-color;
  text-align:center;
  vertical-align:middle;
  z-index:100;
  form {
    position:relative;
    margin:auto auto;
    padding:$micro-size*.5;
    p {
      display:inline-block;
      color:white;
    }
    input {
      display:inline;
      appearance:none;
      border:none;
      background-color:$quintary-color;
      border-radius:3px;
    }
    input[type=email] {
      background-color:rgba(0,0,0,0.1);
      margin-left: $fly-size;
    }
    input[type=submit] {
      font-size:$fly-size;
      color:white;
    }
  }
}

.cart-icon {
  position:absolute;
  top:$small-size;
  left:$small-size;
  height:$med-size;
  color:white;
  &:hover {
    color:$quintary-color;
    transform:scale(1.5);
  }
}

.incart-icon {
  position:absolute;
  top:-($large-size*1.3);
  left:53%;
  width:$med-size;
  color:$quintary-color;
}

.cart-qty {
  position:absolute;
  display:table-cell;
  top:$fly-size;
  left:$med-size;
  width:$small-size;
  height:$small-size;
  border-radius:50%;
  padding:$micro-size;
  background-color:$quintary-color;
  color:white;
  font-size:$micro-size;
  font-weight:900;
  text-align:center;
  virtical-align:middle;
  box-shadow: 0px 0px 10px rgba(black, .2);
}

.drop-animation {
  animation-name:drop;
  animation-duration:0.5s;
}

.compare,
.cart {
  width:40%;
  min-width:500px;
  height:100vh;
  background-color:$quaternary-color;
  padding:$small-size;
  padding-top:$title-size;
  position:fixed;
  top:0;
  left:-200%;
  box-shadow: 0px 0px 20px rgba(black, .2);
  z-index:1000;
  overflow: scroll;
  .compare-close,
  .cart-close {
    position:absolute;
    top:$fly-size;
    right:$fly-size;
    height:$small-size;
    width:$small-size;
    &:hover {
      color:white;
    }
  }
  .cart-items {
    .cart-item {
      position:relative;
      margin-bottom:$small-size;
      padding-top:$fly-size;
      border-top:solid 1px white;
      .cart-item-image {
        position:absolute;
        display:inline-block;
        width:$med-size;
        height:$med-size;
        background-size:contain;
        background-repeat:no-repeat;
        background-position: center center;
        background-color:white;
        border-radius:$micro-size;
        margin-right:$small-size;
      }
      .cart-item-details {
        position: inline-block;
        margin-left: $med-size*1.3;
        margin-right: $med-size*1.3;
        .cart-item-name {
          font-weight:900;
          color:$primary-color;
          margin-bottom:$micro-size;
        }
        .cart-item-price {
          display:block;
          span {
            font-weight:400;
            color:$tertery-color;
            &:first-child {
              font-weight:900;
              margin-right:$micro-size;
            }
          }
        }
      }
      .cart-item-remove {
        position:absolute;
        top:$fly-size;
        right:0;
        height:$fly-size;
        width:$fly-size;
        background-color:$tertery-color;
        color:white;
        font-weight:900;
        padding:$micro-size*0.5;
        text-align:center;
        virtical-align:middle;
        &:hover {
          background-color:$alert-error;
          color:white;
        }
      }
    }
  }
}

.donate-button {
  position:absolute;
  left:$small-size;
  right:$small-size;
  bottom:$large-size;
  display:block;
  bottom:$fly-size;
  font-size:$small-size;
  font-weight:400;
  padding:$small-size*.5 $small-size;
  margin-top: 0;
  background-color:$quintary-color;
  cursor:pointer;
  &:hover {
    background-color:$tertery-color;
  }
}

.compare-closed,
.cart-closed {
  left:-200%;
}
.compare-open,
.cart-open {
  left:0;
}

.auth-link {
  display:inline-block;
  font-family:$system-font;
  font-size:$med-size !important;
  background-color:$quintary-color;
  padding:$small-size*.5 $small-size;
  border-radius:$micro-size;
  color:white;
  width:auto;
  text-transform:uppercase;
}

.feature-list {
  margin-top:$small-size;
  li {
    padding:$fly-size;
    .feature-icon {
      display:inline-block;
      width:$small-size;
      border-radius:$micro-size;
      margin-right:$fly-size;
    }
    p {
      font-size:$small-size;
      color:$quaternary-color;
    }
  }
}

.stop-scroll {
  overflow:hidden;
  &::after {
    content: '';
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background:black;
    opacity:0.8;
    z-index:100;
  }
}

.charity-details-wrapper {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:block;
  width:60rem;
  height:40rem;
  margin:auto auto;
  overflowX: scroll;
  padding:$large-size;
  background-color:white;
  box-shadow: 0px 0px 20px rgba(black, .2);
  border-radius: $small-size;
  overflowY:scroll;
  z-index:1000;
  h3 {
    margin:$fly-size;
  }
  .close-btn {
    position:absolute !important;
    top:$small-size;
    right:$small-size;
    height:$small-size;
    width:$small-size;
  }
  .charity-details {
    width:80%;
    margin:auto auto;
    text-align:center;
    h2 {
      font-size: $med-size;
      font-weight:400;
      color:$tertery-color;
      margin-bottom:$med-size;
    }
    .charity-icon {
      position:relative;
      width:$title-size;
      height:$title-size;
      margin:0 auto $small-size auto;
      background-size:contain;
      background-repeat:no-repeat;
      background-position: center center;
      background-color:white;
    }
  }
}


.intro-wrapper {
  .intro {
    position:fixed;
    display: block;
    top:0;
    height:100vh;
    width:100vw;
    background: rgba($secondary-color,0.9);
    z-index:1000;
    .text {
      position:absolute;
      text-align: center;
      width:80%;
      top:30%;
      left:50%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      p {
        color:white ;
        font-family:$corp-font;
        font-size:$med-size*1.3;
        font-weight:900;
        line-height:$med-size*1.5;
      }
      p+p {
        color:$primary-color;
        margin-bottom:$small-size;
      }
      button {
        font-size:$med-size;
        padding:$fly-size $small-size;
        margin-bottom:$med-size;
      } 
      a {
        text-decoration: underline;
      }
    }
  }
  .hideIntro {
    top:-150%;
    animation-name: introfadeout;
    animation-duration: 1s;
  }
}

.get-layout {
  background-color:$secondary-color;
}

.profile {
  width:80vw;
  margin: auto auto;
  margin-top:$title-size*3;
  text-align:left;
  vertical-align:top;
}

button {
  background-color:$primary-color;
  border:none;
  color:white;
  border-radius:$fly-size;
  padding:$micro-size*.5 $micro-size;
  margin:$micro-size;
  font-weight:900;
  text-transform:uppercase;
  &:hover {
    background-color:$tertery-color;
    animation-name: buttonchange;
    animation-duration: 1s;
  }
}

table {
  margin:$fly-size;
  width:100%;
  tr {
    width:100%;
    td {
      display:table-cell
      button {
        font-size:$micro-size*.5;
      }
    }
  }
}

.chart {
  position:relative;
  display:inline-block;
  width:calc( 50% - 20px );
  min-height:$title-size*5;
  background-color:white;
  padding:$small-size;
  border-radius: $small-size;
  text-align:left;
  vertical-align:top;
  .big-number {
    font-size:10vmax;
    margin-bottom:$small-size;
  }
  canvas {
    margin:auto auto;
  }
}

.chart + .chart {
  margin-left:40px
}

.loading {
  display:block;
  margin:$med-size;
  font-size:$med-size;
  color:$quaternary-color;
}

.avios {
  position:relative;
  display:inline-block;
  margin:auto auto;
  width:$large-size*1.5 ;
  height:$large-size*1.5;
  background-image:url(../styles/avios.png);
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
  opacity:.6;
}

.footer {
  position:fixed;
  display:block;
  width:100%;
  bottom:$large-size;
  text-align:center;
  .compare-block {
    color:white;
    margin-bottom:$small-size;
    .hero-compare {
      font-family:$corp-font;
      font-size:$large-size;
      margin-bottom:$micro-size;
      &:hover {
        font-size:$large-size*1.5;
        text-shadow: 0 0 $small-size rgba( 0, 0, 0, 0.2 );
      }
    }
  }
  .social-wrapper {
    li {
      display:inline-block;
      color:$quaternary-color;
      font-size:$med-size;
      margin:$small-size;
    }
  }
}

.get-route .footer, 
.give-route .footer {
  position:fixed;
  height:auto;
  width:$med-size*2;
  text-align:center;
  bottom:0;
  right:0;
  animation-name:scrolledfooter;
  animation-duration:0.25s;
  .compare-block {
    display:none;
  }
  .avios {
    margin: 0 $fly-size;
    color:$quaternary-color;
    width:$med-size;
    height:$med-size;
    opacity:1;
  }
  .social-wrapper {
    li {
      display:block;
      color:$quaternary-color;
      font-size:$small-size;
      margin:$fly-size;
    }
  }
}

.logo {
  width:$title-size*3;
  height:$large-size*1.8;
  margin:auto;
  margin-top:$micro-size;
  background-image:url(./logo-rev-01.svg);
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center center;
}

.header {
  position:fixed;
  //move header down for watch-list
  left:0;
  width:100%;
  height:$large-size*3;
  z-index:1;
  .background {
    position:absolute;
    top:-100%;
    display:block;
    width:100%;
    height:100%;
    z-index:-1;
  }
  .nav {
    position:absolute;
    top:0;
    right:0;
    display:inline-block;
    margin:$micro-size;
    vertical-align:top;
    .nav-item {
      display:inline-block;
      margin-left:1px;
      padding:$micro-size $fly-size;
      background-color:white;
      font-size:$micro-size; 
      clear:none;
      a {
        text-decoration:none;
        font-weight:900;
        font-size:$micro-size;
        color:$tertery-color;
      }
      &:first-child {
        border-radius: $micro-size*.5 0 0 $micro-size*.5;
      }
      &:last-child {
        border-radius: 0 $micro-size*.5 $micro-size*.5 0;
      }
      &:hover {
        background-color: rgba( $quaternary-color, .4 );
        animation: bg-fadeout, 5s;
        a {
          color:white;
        }
      }
    }
  }
  .nav-button {
    display:none;
  }
}

.scrolled {
  .background {
    top:0;
    animation-name:scrolled;
    animation-duration:1s;
  }
}

.unscrolled {
  .background {
    top:-100;
    animation-name:scrolled;
    animation-duration:1s;
    animation-direction:reverse;
  }
}


.refresh-shield {
  position: absolute;
  top:0;
  left:50%;
  transform: translateX(-50%);
  width:$title-size*3;
  height:$title-size*1.5;
  cursor:pointer;
  z-index:1000;
}

.hero {
  display:block;
  vertical-align:middle;
  height:100vh ;
  text-align:center;
  overflow:hidden !important;
  .benifits, 
  .auth-wrapper,
  .search-wrapper {
    display:none;
    padding-bottom:$title-size*2;
    opacity:0;
  }
  .mission {
    position:absolute;
    bottom:$small-size;
    left:0;
    right:0;
    z-index:10;
    p {
      font-family:$system-font;
      font-size:$fly-size;
      line-height:$fly-size*1.33;
      color:white;
    }
    p + p {
      font-weight:900;
    }
  }

  .hero-bg {
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-image:url(./hero-bg.svg);
    background-size:cover;
    background-position:center center;
  }
  .hero-give,
  .hero-get {
    margin:0;
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    width:50%;
    font-family:$corp-font;
    font-weight:900;
    font-size:$title-size*2;
    color:$quaternary-color;
    cursor:pointer;
      .auth-wrapper {
        top:$title-size*2;
        padding:$small-size;
      }
    .descriptor {
      display:block;
      font-size:$small-size;
      font-family:$system-font;
      font-weight:400;
    }
    &:hover {
      font-size:$title-size*2.5;
      text-shadow: 0 0 $small-size rgba( 0, 0, 0, 0.2 );
      //animation: mouseOver 1s;
    }
    &:not(:hover) {
      font-size:$title-size*2;
      //animation: mouseOut 1s;
    }
  }
  .hero-give {
    left:0;
  }
  .hero-get {
    left:50%;
  }
}

.info-stack {
  display:block;
  height:auto;
  max-width:960px;
  margin: auto auto;
  h1+p {
    color:white ;
    text-align:center;
    font-size:$small-size*1.3;
  }
  &:first-child {
    padding-top:$title-size*2;
  }
}

.info-panel {
  position:relative;
  display:block;
  width:80vw;
  max-width:960px;
  background-color:$quaternary-color;
  margin:$med-size auto; 
  border-radius:$small-size;
  overflow:hidden;
  box-shadow: 0px 0px 20px rgba(black, .2);
  ul {
    li {
      position:relative;
      display:table;
      min-height:$title-size*5;
      .image-panel,
      .text-panel {
        position:relative;
        display:table-cell;
        width:50%;
        min-height:$title-size*5;
      }
      .image-panel {
        background-repeat:no-repeat;
        background-size:cover;
        background-position:center;
      }
      .text-panel {
        padding:$small-size $med-size;
        h2 {
          font-size:$large-size;
        }
        p {
          line-height:$med-size;
        }
      }
      &:nth-child(even) {
        background-color:white;
      }
    }
  }
}

.give-route {
  width:100vw;
  background-color:$primary-color;
  .header > .background {
    background: linear-gradient(to bottom, #25C3B1 70%, transparent 100%, );
  }
  .hero-bg {
    background-position:left;
    animation: bg-get 1.5s;
  }
  .mission {
    display:none;
  }
  .search-wrapper {
    display:block;
    animation: show-search 1s ease-in;
    opacity:1;
    .search-header {
      background-color:rgba($secondary-color, 0.3);
      margin: $med-size auto 0 auto;
      width:70%;
      text-align:left;
      padding:$small-size;
      border-radius:$small-size;
      p {
        font-size:$fly-size;
        font-family:$system-font;
      }
    }
    .charity-search {
      display:inline-block;
      -webkit-appearance:none;
      appearance:none;
      border:none;
      outline:none;
      background-color:rgba($quaternary-color, .5);
      border-radius: $micro-size;
      font-size:$title-size;
      text-transform:uppercase;
      padding:$micro-size*.5 $micro-size;
      color:white;
      text-align:center;
      animation: pulse 3s ease-in;
      animation-iteration-count: infinite;
      vertical-align:middle !important;
      box-shadow: 0px 0px 20px rgba(black, .2);
      width:70%;
      &:focus {
        animation:none;
        transform:scale(inherit);
        background-color:$quaternary-color;
        color:$primary-color;
      }
      &::-webkit-search-cancel-button{
          position:relative;
          right:20px;
          -webkit-appearance: none;
          background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
          background-size: contain;
          color:$primary-color;
          height: 20px;
          width: 20px;
          border-radius:10px;
      }
      &::placeholder {
        font-size:$med-size;
        font-family:$system-font;
        color:$primary-color;
      }
      &::placeholder:focus {
        outline:none !important;
        color:white !important;
      }
    }
    .charity-search:not(:placeholder-shown) {
      animation:none;
    }
    &::after {
      @include clearfix
    }
    .search-wrapper::after {
      content: '';
      visibility: hidden;
      display: block;
      height: 0;
      clear: both;

    }
  }
  .hero-get {
    left:100%;
    display:none;
  }
  .hero-give {
    top:$title-size*2;
    width:100%;
    //height:100%;
    transform: translateY(0);
    animation:hero-give .5s !important;
  }
  .hero-give::after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;

  }
}

.features {
}

.registration {
  background-color:$secondary-color;
  .form-wrapper {
    top:0 !important;
    h1 {
      text-align:center;
      font-family:$corp-font;
      font-weight:900;
      font-size:$title-size*2;
      color:$quaternary-color;
      margin-bottom:$large-size;
    }
  }
}

.charity-list {
  position:relative;
  display:block;
  list-style:none;
  width:80%;
  margin:auto;
  padding-bottom:$title-size*2;
  padding-top:$title-size;
  clear: both;
  background-color:$primary-color;
  z-index:100000;
  .alert {
    font-family:$system-font;
    font-size:$small-size;
  }
  .charity-card {
    position:relative;
    display:inline-block;
    margin:1%;
    margin-bottom: $med-size;
    padding:$med-size;
    padding-top:$large-size;
    padding-bottom:$med-size;
    width:40%;
    vertical-align:text-top;
    background-color: white;
    height:100%;
    min-height: 17rem;
    border-radius:$fly-size;
    text-align:center;
    .details-button {
      vertical-align:middle;
      color:$quintary-color;
    }
    .charity-icon {
      position:absolute;
      top:-4.5rem;
      left:0;
      right:0;
      display:block;
      margin: $small-size auto;
      width:$title-size;
      height:$title-size;
      background-size:contain;
      background-repeat:no-repeat;
      background-position: center center;
      border-radius: 50%;
      border:solid $micro-size white;
      background-color:white;
    }
    .empty {
      background-color:$tertery-color;
      text-align:center;
      p {
        color:white;
        vertical-align:middle;
        top:50%;
        transform:translateY(50%);
        font-size:$med-size;
        font-weight:900;
        line-height:$small-size*1.3;
      }
    }
    h3,
    p {
      font-size:$fly-size;
      font-family:$system-font;
      color:$primary-color;
      margin-bottom:$fly-size;
    }
    p {
      font-weight:400;
      color:$tertery-color;
    }
    h3 {
      overflow:hidden;
      text-overflow:ellipsis;
      text-transform:uppercase;
      color:$primary-color
    }
    .details-button {
      width:40%;
      float:right;
      text-align:right;
      padding-right:$med-size;
    }
    .location {
      width:60%;
      float:left;
      text-align:left;
      padding-left:$med-size;
      color:$quintary-color;
    }
    .donation-group {
      position:relative;
      display:block;
      margin-bottom:$fly-size;
      .fly {
        font-size:$micro-size !important;
      }
      &::after {
        content:'£';
        position:absolute;
        top:$micro-size;
        left:$micro-size;
        padding:$fly-size*.5 $fly-size;
        font-size:$fly-size;
        font-weight:400;
        color:$tertery-color;
      }
      input[type=tel] {
        font-size:$small-size;
        font-weight:400;
        width:60%;
        padding:$fly-size*.5 $fly-size;
        padding-left:$small-size;
        background-color:$quaternary-color;
        border-radius: $fly-size 0 0 $fly-size;
        appearance:none;
        border:none;
      }
      input[type=submit] {
        display:inline-block;
        appearance:none;
        width:auto;
        font-size:$small-size;
        font-weight:400;
        text-transform:uppercase;
        padding:$fly-size*.5 $fly-size;
        background-color:$primary-color;
        border-radius: 0 $fly-size $fly-size 0;
        border:none;
        margin-left: 0;
        color:white;
      }
    }
    .donated {
      margin:0;
      font-weight:900;
      color:$quintary-color;
    }
    .charity-description {
      white-space:nowrap;
      max-width: 55ch;
      overflow:hidden;
      text-align:center;
      text-overflow:ellipsis;
      margin: auto auto;
      margin-bottom:$small-size;
    }
  }
}
.donated-link {
  margin-top:0;
  font-weight:900;
  color:$quintary-color;
  text-decoration:underline ;
  cursor:pointer;
}
.give-revert {
  .hero-bg {
    background-position:center;
    animation: bg-get 1.5s reverse;
  }
}
.get-revert {
  .hero-bg {
    background-position:center;
    animation: bg-give 1.5s reverse;
  }
}
.form-wrapper,
.auth-wrapper {
  .auth-btn {
    display:inline-block;
    width:100%;
    font-family:$system-font;
    font-size:$large-size;
    padding:$micro-size*.5 $micro-size;
  }
  input[type=text],
  input[type=submit],
  input[type=email],
  input[type=password] {
    display:block;
    border:none;
    background-color:rgba($quaternary-color, .5);
    border-radius:$micro-size;
    font-size:$large-size;
    width:100%;
    color:white;
    text-align:center;
    box-shadow: 0px 0px 20px rgba(black, .2);
    margin:$micro-size;
    &:focus {
      animation:none;
      transform:scale(inherit);
      background-color:$quaternary-color;
      color:$secondary-color;
    }
    &::placeholder {
      font-size:$med-size;
      line-height:$title-size;
      font-family:$system-font;
      color:$secondary-color;
    }
    &::placeholder:focus {
      color:white !important;
    }
    
  }
  input[type=submit] {
    background-color:white;
    color:$secondary-color;
  }
}

.form-wrapper {
  margin-top:$title-size*2;
  form {
    max-width:600px;
    height:auto;
    margin:auto auto;
  }
}
.no-route {
  background: {
    display:none;
  }
}
.get-route {
  width:100vw;
  //padding-bottom:$title-size;
  .mission {
    display:none;
  }
  .header {
    .background {
      background: linear-gradient(to bottom, #2588c3 70%, transparent 100%, );
    }
  }
  .hero-bg {
    background-position:right;
    animation: bg-give 1.5s;
  }
  .get-info-wrapper {
    width:100%;
  }
  .auth-wrapper {
    position:absolute;
    top:$title-size*5;
    left:0;
    right:0;
    display:inline-block;
    max-width:960px;
    margin:auto auto;
    animation: show-benifits 1s ease-in;
    opacity:1;
  }
  .benifits {
    position:absolute;
    display:initial;
    top:50%;
    left:0;
    width:100%;
    transform: translateY(-50%);
    opacity:1;
    animation: show-benifits 1s ease-in;
    li {
      display:inline-block;
      margin:$small-size;
      vertical-align:top;
      border-radius:$small-size;
      background-color:$quaternary-color;
      h3 {
        font-size:$med-size;
        padding:$small-size;
        width:$title-size*3.5;
        font-family:$corp-font;
        color:$secondary-color;
      }
      //&::before {
        //content: '';
        //width:$med-size;
        //height:$med-size;
        //display:block;
        //margin: auto;
        //background-image:url('../styles/benifits -03.svg');
        //background-size:contain;
        //background-repeat:no-repeat;
        //background-position:center;
      //}
      //&:first-child::before {
        //background-image:url('../styles/benifits -01.svg');
      //}
      //&:last-child::before {
        //background-image:url('../styles/benifits -02.svg');
      //}
    }
  }
  .hero-give {
    left:-100%;
    display:none;
  }
  .hero-get {
    top:$title-size*2;
    left:0;
    width:100%;
    transform: translateY(0);
    animation:hero-get .5s !important;
  }
}
.get-route,
.give-route {
  .hero-give:hover,
  .hero-get:hover {
    font-size:$title-size*2;
    cursor:default;
    animation:none;
  }
  .hero-give:not(:hover),
  .hero-get:not(:hover){
    animation:none;
  }
}

.alert {
  display: inline-block;
  margin: auto $small-size;
  padding:$fly-size $small-size;
  background-color: $alert-nominal;
  border-radius: $micro-size;
  color: white;
}

.alert:empty {
  display: none;
}

.alert-error {
  background-color: $alert-error;
}


@keyframes bg-give {
  0% {
    background-position:center;
  }
  100% {
    background-position:right;
  }
}
@keyframes bg-get {
  0% {
    background-position:center;
  }
  100% {
    background-position:left;
  }
}


@keyframes bg-revert {
  0% {
    background-position:inherit;
  }
  100% {
    background-position:center;
  }
}

@keyframes hero-get {
  0% {
    top:50%;
    transform: translateY(-50%);
    width:50%;
    left:50%;
  }
  100% {
    top:$title-size*2;
    left:0;
    width:100%;
    transform: translateY(0);
  }
}
@keyframes hero-give {
  0% {
    top:50%;
    width:50%;
    left:0;
  }
  100% {
    top:$title-size*2;
    left:0;
    width:100%;
    transform: translateY(0);
  }
}

@keyframes scrolled {
  0% {
    top:-100%;
    opacity:0;
  }
  100% {
    top:0;
    opacity:1;
  }
}


@keyframes scrolledfooter {
  0% {
    width:100%;
  }
  100% {
    width:$med-size*2;
  }
}

@keyframes mouseOver {
  0% {
    font-size:$title-size*2;
  }
  100% {
    font-size:$title-size*2.5
  }
}
@keyframes mouseOut {
  0% {
    font-size:$title-size*2.5
  }
  100% {
    font-size:$title-size*2;
  }
}

@keyframes bg-fadeout {
  0% {
    opacity:1;
  }
  100% {
    opacity:.4;
  }
}
@keyframes bg-fadein {
  0% {
    background-color:rgba($quaternary-color, .4)
  }
  100% {
    background-color:rgba($quaternary-color, 1)
  }
}

@keyframes show-search {
  0% {
    opacity:0;
  }
  40% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
} 
@keyframes show-benifits {
  0% {
    opacity:0;
  }
  40% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@keyframes pulse {
  0% {
    background-color:rgba($quaternary-color, 1);
    transform:scale(100%);
    box-shadow: 0px 0px 20px rgba(black, .2);
  }
  50% {
    background-color:rgba($quaternary-color, .5);
    transform:scale(90%);
    box-shadow: 0px 0px 0px rgba(black, .2);
  }
  100% {
    background-color:rgba($quaternary-color, 1);
    transform:scale(100%);
    box-shadow: 0px 0px 20px rgba(black, .2);
  }
}

@keyframes introfadeout {
  0% {
    top:0;
  }
  100% {
    top:-100%;
  }
}

@keyframes buttonchange {
  0% {
    background-color:inherit;
  }
  100% {
    background-color:$tertery-color;
  }
}

@keyframes drop {
  0% {
    top:-($small-size);
    opacity:0;
  }
  100% {
    top:$fly-size;
    opacity:1;
  }
}

@keyframes form-label {
  0% {
    top:0;
    font-size:$small-size;
  }
  100% {
    top:-($small-size);
    font-size:$micro-size;
  }
}

@media only screen and (orientation:portrait) {
  html {
    font-size:9px !important;
  }
  .watch-list {
    display:none;
  }
  .hero {
    .search-wrapper {
    }
    .hero-give {
      top:30%;
      width:60%;
      animation:none;
      &:hover {
        animation:none !important;
      }
    }
    .hero-get {
      transform: translateY(30%);
      width:60%;
      left:40%;
      &:hover {
        animation:none !important;
      }
    }
  }
  .header {
    .logo {
      margin-top:0;
      width:80vw;
      height:20vw;
    }
    .nav {
      display:none;
    }
  }
  p.hero-give,
  p.hero-get {
    padding:$small-size;
    p {
      width:100%;
    }
  }
  p.hero-give {
    width:70%!important;
  }
  .hero-get {
    right:$small-size;
    left:20%;
  }
  .get-route,
  .give-route {
    .search-wrapper {
      .charity-list {
        .charity-card {
          width:100%;
        }
      }
    }
    .auth-wrapper {
      width:100vw !important;
    }
    .hero-give:hover,
    .hero-get:hover {
      animation:none !important;
    }
    .hero-get {
      margin:auto auto;
      width:100% !important;
      top:$title-size !important;
      left:0;
      padding:$small-size;
    }
    .hero-give {
      margin:auto auto;
      width:100% !important;
      left:0;
      padding:$small-size;
    }
  }
}
